<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <loading v-show="pageLoading"/>
            <div v-show="!pageLoading">
                <ValidationProvider name="contact_city_id" rules="required" v-slot="{valid, errors}">
                    <b-form-group>
                        <label>{{ $t('city') }}</label>
                        <city-selectbox v-model="formData.contact_city_id"
                                        :validateError="errors[0]"
                        />
                    </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="contact_district_id" rules="required" v-slot="{valid, errors}">
                    <b-form-group>
                        <label>{{ $t('district') }}</label>
                        <district-outline-selectbox v-model="formData.contact_district_id"
                                                    :city_id="formData.contact_city_id"
                                                    :validateError="errors[0]"
                        />
                    </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="contact_address" rules="required" v-slot="{valid, errors}">
                    <b-form-group>
                        <label>{{ $t('address') }}</label>
                        <b-form-textarea v-model="formData.contact_address"
                                         :state="errors[0] ? false : null"
                                         no-resize
                                         rows="4"
                        />
                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                    </b-form-group>
                </ValidationProvider>
                <div class="d-flex justify-content-center">
                    <b-button variant="primary"
                              @click="updateForm"
                              :disabled="formLoading">
                        {{ $t('update') }}
                    </b-button>
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import CitySelectbox from '@/components/interactive-fields/CitySelectbox.vue';
    import DistrictOutlineSelectbox from '@/components/interactive-fields/DistrictOutlineSelectbox.vue';
    import Loading from '@/components/elements/Loading.vue';

    // Services
    import PersonService from '@/services/PersonService'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            CitySelectbox,
            DistrictOutlineSelectbox,
            Loading,

            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                pageLoading: false,
                formLoading: false,
                formData: {
                    contact_city_id: null,
                    contact_district_id: null,
                    contact_address: null
                }
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get() {
                this.pageLoading = true;
                PersonService.getAddress()
                             .then(response => {
                                 let data = response.data.data
                                 this.formData = {
                                     contact_city_id: data.contact_city_id,
                                     contact_district_id: data.contact_district_id,
                                     contact_address: data.contact_address
                                 }
                             })
                             .catch((e) => {
                                 this.showErrors(e);
                             })
                             .finally(() => {
                                 this.pageLoading = false
                             })
            },

            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    this.formLoading = true;
                    PersonService.patchAddress(this.formData)
                                 .then(response => {
                                     let message = response.data.message
                                     this.$toast.success(this.$t('api.' + message));
                                     this.$emit('addressUpdated', true)
                                 })
                                 .catch(e => {
                                     this.showErrors(e, this.$refs.addressForm)
                                 })
                                 .finally(() => {
                                     this.formLoading = false;
                                 })
                }
            }
        }
    }
</script>
